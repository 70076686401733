import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React from "react";
import ReviewTermsModal from "./ReviewTermsModal";

function ReviewTerms() {
  return (
    <>
      <Typography mt={3}>
        You must agree to the Terms before submitting a payment.
      </Typography>
      <ReviewTermsModal />
      <FormGroup sx={{ alignContent: "center", my: 2 }}>
        <FormControlLabel
          required
          control={<Checkbox color="secondary" size="large" />}
          label="I Agree"
        />
      </FormGroup>
    </>
  );
}

export default ReviewTerms;
