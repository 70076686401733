import React from "react";
import ContactCustomerService from "../../pages/notification/components/ContactCustomerService";
import Error from "../../pages/notification/components/Error";
import Information from "../../pages/notification/components/Information";
import Maintenance from "../../pages/notification/components/Maintenance";

const components: any = {
    ContactCustomerService,
    Error,
    Information,
    Maintenance
};

interface DynamicNotificationComponentInfo {
    componentName: string;
    title: string;
    message: string;
}

const DynamicNotificationComponent = ({ componentName = "", title = "", message = "" }: DynamicNotificationComponentInfo) => {
    const TagName = components[componentName];
    return TagName ? <TagName title={title} message={message} /> : <ContactCustomerService title={""} message={""} />;
};

export default DynamicNotificationComponent;
