import * as React from "react";
import Modal from "@mui/material/Modal";
import ReportIcon from "@mui/icons-material/Report";
import { Box, Button, Typography } from "@mui/material";

interface ErrorNotificationModalProps {
    openModal: boolean;
    onClose: () => void;
    errorMessage: string;
}

const ErrorNotificationModal = ({ openModal, onClose, errorMessage }: ErrorNotificationModalProps) => {
    const [open, setOpen] = React.useState(openModal);
    const handleClose = () => {
        onClose();
        setOpen(false);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-content-wrapper">
                    <Typography color="error" my={3}>
                        <ReportIcon sx={{ fontSize: 100 }} />
                    </Typography>
                    <Box textAlign="center">
                        <Typography variant="h3" my={3}>
                            Payment Failed
                        </Typography>
                    </Box>
                    <Box textAlign="center" my={3}>
                        <Typography variant="h5">
                            {errorMessage}
                        </Typography>
                    </Box>
                    <Button color="secondary" onClick={handleClose} sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}

export default ErrorNotificationModal;
