import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Stack,
  Link
} from "@mui/material";
import { TenantInformationResponse } from "../../../interfaces/tenantQuickPayPaymentInterfaces";
import { DataContext } from "../../../context/context";
import { Link as RouterLink } from "react-router-dom";
import NotYouModal from "./NotYouModal";

interface TenantInfoProps {
  response: TenantInformationResponse;
}

const ConfirmAccount = ({ response }: TenantInfoProps) => {
  const { setTenantData } = useContext(DataContext);
  const [lastFour, setLastFour] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    setTenantData(response);
    setLastFour(response.paymentInfo.tenantCode.slice(-4));
  }, [response, setTenantData]);

  return (
    <div className="content-wrapper">
      <Box sx={{ margin: "auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Card className={"card-width"}>
            <CardContent>
              <Box textAlign="center">
                <Typography variant="h3" mt={2}>
                  Welcome to Quick Pay
                </Typography>
                <Typography variant="h5" my={3}>
                  If this is your account please continue.
                </Typography>
                <Divider></Divider>
                <Typography gutterBottom variant="h6" mt={3}>
                  Last Name: {response.paymentInfo.lastName}
                </Typography>
                <Typography gutterBottom variant="h6" mt={2}>
                  Account #: xxxx
                  {lastFour}
                </Typography>
              </Box>
            </CardContent>
            <CardContent>
              <Box>
                <Stack spacing={3} justifyContent="center" alignItems="center">
                  <RouterLink to="/payment">
                    <Button color="secondary" size="large" variant="contained">
                      CONTINUE
                    </Button>
                  </RouterLink>
                  <Button onClick={handleModalOpen} color="secondary" size="large" variant="outlined">
                    NOT YOU?
                  </Button>
                  <NotYouModal openModal={openModal} closeModal={handleCloseModal} />
                  <Link href={process.env.REACT_APP_UTILITIES_INFO_URL} variant="subtitle1" color="primary">Or Log In to Pay</Link>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </div>
  );
};

export default ConfirmAccount;
