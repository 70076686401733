import React from "react";
import { Button, Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _colors from "../../../assets/styles/Content/_colors.scss";

const ConserviceCollect = () => {
    return (
        <div className="content-wrapper">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={10}
            >
                <Card className={"card-width"}>
                    <CardContent>
                        <Typography color="lighblue" my={3}>
                            <FontAwesomeIcon icon={faCircleInfo} size="5x" color={_colors.lightblue} />
                        </Typography>
                        <Box textAlign="center" my={3}>
                            <Typography variant="h5">
                                We are unable to find your Quick Pay link. Please sign in to your account to make a payment.
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardContent>
                        <Box textAlign="center" my={3}>
                            <Button
                                href={process.env.REACT_APP_UTILITIES_INFO_URL}
                                variant="contained"
                                color="secondary"
                                size="large"
                            >
                                Sign in to Account
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

export default ConserviceCollect;
