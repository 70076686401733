import React, { useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { SearchByEmailAddressRequest, SearchResponse } from '../../../interfaces/accountVerificationInterfaces';
import { tenantQuickPayService } from "../../../services/tenantQuickPayService";
import { CircularProgress, Backdrop, TextField, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/context";
import Swal from "sweetalert2";
import _colors from "../../../assets/styles/Content/_colors.scss";
import ReCaptchaButton from "../../../components/ReCaptcha/ReCaptchaButton";
import BillInfoModal from "../../../components/BillInfoModal/BillInfoModal";
import ReactGA from "react-ga4";

const SearchEmailAddress = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSearchData } = useContext(DataContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SearchByEmailAddressRequest>({ mode: "all" });

  const onSubmit: SubmitHandler<SearchByEmailAddressRequest> = async (data) => {

    setIsLoading(true);

    try {

      const response: SearchResponse = await tenantQuickPayService.searchEmailAddress(data);

      if (response.isSuccessful) {

        ReactGA.event({ category: "Search", action: `Search Email ${response.collectType}` });

        setSearchData(response);

        if (response.collectType === "Conservice") {
          navigate("/verify-account", { replace: true, state: { contactType: "email" } });
        }
        else {
          navigate("/account-collect", { replace: true });
        }
      }
      else {

        ReactGA.event({ category: "Search", action: "Search Email Failed" });

        Swal.fire({
          text: response.errorMessage,
          icon: 'error',
          confirmButtonText: 'Okay',
          confirmButtonColor: _colors.primaryblue,
        })

      }

    } catch (e) {
      console.log(e);

      Swal.fire({
        text: 'Something went wrong while processing your request, please try again later.',
        icon: 'error',
        confirmButtonText: 'Okay',
        confirmButtonColor: _colors.primaryblue,
      })
    }

    setIsLoading(false);
  };

  const onVerifyCaptcha = (token: string) => {
    setValue("recaptchaToken", token);
  };


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mt={3} mb={2} mr={4} ml={4}>
          <TextField
            label="Last four Account Number"
            InputProps={{ endAdornment: <BillInfoModal /> }}
            autoComplete="off"
            size="small"
            sx={{ mt: 1 }}
            error={!!errors.lastFourAccountNumber}
            helperText={errors.lastFourAccountNumber ? errors.lastFourAccountNumber?.message : ""}
            {...register("lastFourAccountNumber", {
              required: "Last four account number is required",
              pattern: {
                value: /^\d+$/,
                message: "Numeric numbers only",
              },
              maxLength: {
                value: 4,
                message: "Last four account number exceeds maximum length",
              },
            })}
          />
          <TextField
            label="Email Address"
            autoComplete="off"
            size="small"
            sx={{ mt: 1 }}
            error={!!errors.emailAddress}
            helperText={errors.emailAddress ? errors.emailAddress?.message : ""}
            {...register("emailAddress", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/,
                message: "Invalid Email Address",
              },
              maxLength: {
                value: 160,
                message: "Email address exceeds maximum length",
              },
            })}
          />
        </Stack>
        <ReCaptchaButton onVerifyCaptcha={onVerifyCaptcha} text="Search" action="SearchEmailAddress" isLoading={isLoading} />
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default SearchEmailAddress;
