import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import defaultTheme from "./assets/styles/Themes/DefaultTheme";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/NavBar/NavBar";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/home/Home";
import AccountConfirmationPage from "./pages/account/AccountConfirmationPage";
import Notification from "./pages/notification/NotificationPage";
import DataProvider from "./context/context";
import PaymentPage from "./pages/payment/PaymentPage";
import AccountCollectTypePage from "./pages/CollectType/AccountCollectTypePage";
import ReactGA from "react-ga4";
import "./App.css";
import PaymentConfirmation from "./pages/payment-confirmation/PaymentConfirmationPage";
import VerifyAccountPage from "./pages/verify-account/VerifyAccountPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: (
      <Notification
        title="404"
        message="404 error page not found"
      />
    ),
  },
  {
    path: "/account",
    element: <AccountConfirmationPage />,
    errorElement: (
      <Notification
        title="404"
        message="404 error page not found"
      />
    ),
  },
  {
    path: "/payment",
    element: <PaymentPage />,
    errorElement: (
      <Notification
        title="404"
        message="404 error page not found"
      />
    ),
  },
  {
    path: "/payment-confirmation",
    element: <PaymentConfirmation />,
    errorElement: (
      <Notification
        title="404"
        message="404 error page not found"
      />
    ),
  },
  {
    path: "/account-collect",
    element: <AccountCollectTypePage />,
    errorElement: (
      <Notification
        title="404"
        message="404 error page not found"
      />
    ),
  },
  {
    path: "/verify-account",
    element: <VerifyAccountPage />,
    errorElement: (
      <Notification
        title="404"
        message="404 error page not found"
      />
    ),
  },
]);

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_ID;
ReactGA.initialize(`${TRACKING_ID}`);

export default function App() {



  return (
    <div className="App">
      <DataProvider>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <Navbar />
          <RouterProvider router={router} />
          <Footer />
        </ThemeProvider>
      </DataProvider>
    </div>
  );
}
