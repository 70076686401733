import axios from "axios";
import { OneTimeCCPaymentRequest } from "../interfaces/tenantQuickPayPaymentInterfaces";
import { SearchByEmailAddressRequest, SearchByPhoneNumberRequest, VerifyVerificationCodeRequest } from "../interfaces/accountVerificationInterfaces";
import { getStutusCodeErrors } from "../utils/helpers/errorHelpers";


const url = process.env.REACT_APP_TENANT_QUICKPAY_SERVICE_URL;

const api = axios.create({
  baseURL: url,
  headers: {
    AppName: "QuickPay",
  },
});

const getPaymentInfo = (token: string) => {
  const REQUEST_URL = `/Account/GetTenantQuickPayPaymentInfo?Token=${token}`;

  const result = api.get(REQUEST_URL).then((response) => {
    return response.data;
  }).catch((error) => {

    const status = (error.response != null && error.response.status === 503) ? "503" : "400";
    const errorObject = getStutusCodeErrors(status);
    return errorObject;

  });
  return result;
};

const makeOneTimeCCPayment = (paymentRequest: OneTimeCCPaymentRequest) => {
  const apiAuth = axios.create({
    baseURL: url,
    headers: {
      AppName: "QuickPay",
      Authorization: `Bearer ${paymentRequest.authToken}`,
    },
  });

  const REQUEST_URL = `/Payment/MakeOneTimeCreditCardPayment`;

  const result = apiAuth.post(REQUEST_URL, paymentRequest).then((response) => {
    return response.data;
  }).catch((error) => {
    const status = (error.response != null && error.response.status === 503) ? "503" : "400";
    const errorObject = getStutusCodeErrors(status);
    return errorObject;
  });;

  return result;
};

const searchEmailAddress = (request: SearchByEmailAddressRequest) => {
  const REQUEST_URL = `/Account/SearchByEmailAddress`;

  const result = api.post(REQUEST_URL, request).then((response) => {
    return response.data;
  }).catch((error) => {
    const status = (error.response != null && error.response.status === 503) ? "503" : "400";
    const errorObject = getStutusCodeErrors(status);
    return errorObject;
  });

  return result;
};

const SearchPhoneNumber = (request: SearchByPhoneNumberRequest) => {
  const REQUEST_URL = `/Account/SearchByPhoneNumber`;

  const result = api.post(REQUEST_URL, request).then((response) => {
    return response.data;
  }).catch((error) => {
    const status = (error.response != null && error.response.status === 503) ? "503" : "400";
    const errorObject = getStutusCodeErrors(status);
    return errorObject;
  });

  return result;
};

const verifyVerificationCode = (request: VerifyVerificationCodeRequest) => {
  const REQUEST_URL = `/Account/verifyVerificationCode`;

  const result = api.post(REQUEST_URL, request).then((response) => {
    return response.data;
  }).catch((error) => {
    const status = (error.response != null && error.response.status === 503) ? "503" : "400";
    const errorObject = getStutusCodeErrors(status);
    return errorObject;
  });

  return result;
};

export const tenantQuickPayService = {
  getPaymentInfo,
  makeOneTimeCCPayment,
  searchEmailAddress,
  SearchPhoneNumber,
  verifyVerificationCode,
};
