import React, { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
    Button,
    MenuItem,
    TextField,
    Typography,
    CircularProgress,
    Backdrop,
    Modal,
    Box,
} from "@mui/material";
import { ZendeskFormInputs, ZendeskRequest, ZendeskResponse } from "../../../interfaces/zendeskTicketFeedbackInterfaces";
import { DataContext } from "../../../context/context";
import { zendeskTicketService } from "../../../services/zendeskTicketFeedbackService";
import { formValidations } from "../../../utils/validations/formValidations";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import ReactGA from "react-ga4";

const issueTypes = [
    { issue: "Wrong Email" },
    { issue: "Wrong Name" },
    { issue: "Wrong Amount" },
    { issue: "Other" },
];

interface NotYouModalProps {
    openModal: boolean;
    closeModal: () => void;
}

const NotYouModal = ({ openModal, closeModal }: NotYouModalProps) => {
    const { tenantData } = useContext(DataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitFailed, setSubmitFailed] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState("");

    const handleClose = () => {
        if (submitFailed) { setSubmitFailed(false); }
        reset()
        closeModal();
    }

    const handleOptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedIssue(event.target.value as string);
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ZendeskFormInputs>({ mode: "all" });

    const onSubmit: SubmitHandler<ZendeskFormInputs> = async (data) => {
        setIsLoading(true);

        const commentBody = data.comment || "No Comment";

        const feedbackRequest: ZendeskRequest = {
            ticket: {
                comment: { body: commentBody },
                priority: "normal",
                subject: "Incorrect Account Information ( " + data.subjectType + " - " + tenantData.paymentInfo.tenantCode + " )",
                zendeskRequester: { name: data.name, email: data.email }
            }
        };

        try {
            const zendeskFeedbackResponse: ZendeskResponse = await zendeskTicketService.submitZendeskTicket(feedbackRequest, tenantData.authToken);

            if (zendeskFeedbackResponse.isSuccessful) {
                setIsLoading(false);
                setSubmitSuccess(true);

                ReactGA.event({ category: "NotYou", action: "Not You Submit" });

            } else {
                setIsLoading(false);
                setSubmitFailed(true);

                ReactGA.event({ category: "NotYou", action: "Not You Submit Failed" });
            }
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: "scroll" }}
            >
                <Box className="form-modal-content-wrapper">
                    <Typography id="wrong-account-title" variant="h6" component="h2">
                        What was wrong with your account?
                    </Typography>
                    {!submitSuccess && !submitFailed && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                label="Name"
                                className={"textbox-width"}
                                autoComplete="off"
                                sx={{ mt: 2 }}
                                error={!!errors.name}
                                inputProps={{ maxLength: 75 }}
                                helperText={errors.name ? errors.name?.message : ""}
                                {...register("name", {
                                    required: "Name is required",
                                    maxLength: {
                                        value: 75,
                                        message: "Name exceeds maximum length",
                                    },
                                    validate: (name) =>
                                        formValidations.nonAsciiCommasCheck(name),
                                })}
                            />
                            <TextField
                                label="Email"
                                className={"textbox-width"}
                                autoComplete="off"
                                sx={{ mt: 2 }}
                                inputProps={{ maxLength: 75 }}
                                error={!!errors.email}
                                helperText={errors.email ? errors.email?.message : ""}
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/,
                                        message: "Invalid Email Address",
                                    },
                                    maxLength: {
                                        value: 75,
                                        message: "Email address exceeds maximum length",
                                    },
                                })}
                            />
                            <TextField
                                select
                                label="Issue Type"
                                className={"textbox-width"}
                                sx={{ textAlign: "left", mt: 2 }}
                                onChange={handleOptionChange}
                                inputProps={register("subjectType", {
                                    required: "Issue Type is required",
                                })}
                                error={!!errors.subjectType}
                                helperText={errors.subjectType?.message}
                                defaultValue={""}
                            >
                                {issueTypes.map((option) => (
                                    <MenuItem key={option.issue} value={option.issue}>
                                        {option.issue}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {selectedIssue === 'Other' && (
                                <TextField
                                    label="Comments"
                                    multiline
                                    className={"textbox-width"}
                                    autoComplete="off"
                                    sx={{ mt: 2 }}
                                    error={!!errors.comment}
                                    inputProps={{ maxLength: 150 }}
                                    helperText={errors.comment ? errors.comment.message : ""}
                                    {...register("comment", {
                                        required: "Please enter some feedback",
                                        maxLength: {
                                            value: 150,
                                            message: "Comment must be less than 150 characters",
                                        },
                                    })}
                                />
                            )}
                            <Box textAlign="center" mt={2} >
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    sx={{ my: 2 }}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    )}
                    {submitSuccess && (
                        <>
                            <CheckCircleOutline sx={{ color: 'green', fontSize: 40 }} />
                            <Typography variant="body1" color="primary">
                                Ticket submitted successfully!
                            </Typography>
                        </>
                    )}
                    {submitFailed && (
                        <>
                            <CancelOutlined sx={{ color: 'red', fontSize: 40 }} />
                            <Typography variant="body1" color="error">
                                Ticket submission failed! Please close the window and try again.
                            </Typography>
                        </>
                    )}
                    <Button color="secondary" onClick={handleClose} sx={{ mt: 2 }}>
                        Close
                    </Button>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            </Modal>
        </div>
    );
}

export default NotYouModal;
