import React, { useEffect, useState } from "react";
import { TenantInformationResponse } from "../../interfaces/tenantQuickPayPaymentInterfaces";
import { tenantQuickPayService } from "../../services/tenantQuickPayService";
import ConfirmAccount from "./components/ConfrimAccount";
import Notification from "../notification/NotificationPage";
import { useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";

const AccountConfirmation = () => {
  const [tenantInformationResponse, setTenantInformationResponse] = useState<TenantInformationResponse>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("p") ?? "";
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Account Confirmation Page" });
  }, []);

  useEffect(() => {
    tenantQuickPayService.getPaymentInfo(token)
      .then((response) => {
        response.token = token;
        setTenantInformationResponse(response);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
  }, [token]);

  return (
    <>
      {!isLoading && (
        <>
          {!tenantInformationResponse?.isSuccessful &&
            <Notification
              title={tenantInformationResponse?.errorCode}
              message={tenantInformationResponse?.errorMessage} />
          }

          {tenantInformationResponse?.isSuccessful && <ConfirmAccount response={tenantInformationResponse} />}
        </>
      )}
    </>
  );
};

export default AccountConfirmation;
