import ReportIcon from "@mui/icons-material/Report";
import { Box, Typography } from "@mui/material";

interface ErrorMessageProps {
    errorCode: string;
    errorMessage: string;
}

const ErrorMessage = ({ errorCode, errorMessage, }: ErrorMessageProps) => {
    return (
        <>
            <Typography color="error" my={3}>
                <ReportIcon sx={{ fontSize: 100 }} />
            </Typography>
            <Box textAlign="center">
                <Typography variant="h3" my={3}>
                    {errorCode}
                </Typography>
            </Box>
            <Box textAlign="center" my={3}>
                <Typography variant="h5">
                    {errorMessage}
                </Typography>
            </Box>
        </>
    );
}

export default ErrorMessage;