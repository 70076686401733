import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../context/context";
import { Box, Grid, Card, Typography, Button } from "@mui/material";
import { getDateString } from "../../utils/helpers/dateHelper";
import FeedbackFormModal from "./components/FeedbackFormModal";
import ReactGA from "react-ga4";

const PaymentConfirmation = () => {
  const { confirmationData } = useContext(DataContext);
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Payment Confirmation Page" });
  }, []);


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={5}
      >
        <Card className={"card-width"}>
          <Box textAlign="center" my={3}>
            <Typography variant="h4">Thank you for your payment!</Typography>
            <Typography variant="subtitle1" mt={1} mb={2}>
              Please allow up to 3 business days for your payment to be posted to your account.
            </Typography>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography gutterBottom variant="h5">
              Confirmation Number: {confirmationData.confirmationCode}
            </Typography>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography gutterBottom variant="h5">
              Payment: ${confirmationData.paymentAmount.toFixed(2)}
            </Typography>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography gutterBottom variant="h5">
              Fee Amount: ${confirmationData.convenienceFee.toFixed(2)}
            </Typography>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography gutterBottom variant="h5">
              Total Paid: ${confirmationData.totalAmount.toFixed(2)}
            </Typography>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography gutterBottom variant="h5">
              Date: {getDateString()}
            </Typography>
          </Box>
          <Box textAlign="center" mt={7}>
            <Typography gutterBottom variant="h6">
              Optimize your account with
            </Typography>
          </Box>
          <Box textAlign="center" my={2}>

            <Button
              href={process.env.REACT_APP_UTILITIES_INFO_URL}
              onClick={() => { ReactGA.event({ category: "Payment Confirmation", action: "Redirect to UI AutoPay" }); }}
              variant="contained"
              color="secondary"
              size="large"
            >
              Autopay
            </Button>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography gutterBottom variant="h6">
              Provide Feedback:
            </Typography>
            <Button
              onClick={handleModalOpen}
              variant="contained"
              color="secondary"
              size="large"
            >
              Feedback
            </Button>
            <FeedbackFormModal openModal={openModal} closeModal={handleCloseModal} />
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default PaymentConfirmation;
