import React from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";

import "../NavBar/NavBar.scss";

const devEnvType =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "DEV"
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? "STAGING"
    : "";

const Navbar = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary" className="app-bar">
          <Toolbar>
            <Box>
              <img
                className="navbar-logo"
                src="/Images/ConserviceLogoWhiteColor.png"
                alt="Conservice Logo"
              />
            </Box>
            {devEnvType && (
              <Box sx={{ padding: 1 }} className={"test-env"}>
                <Typography>TEST {devEnvType}</Typography>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
