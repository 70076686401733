import React from "react";
import { Button, Box, Grid, Card, CardContent } from "@mui/material";
import ErrorMessage from "./ErrorMessage";

interface ErrorProps {
  title: string;
  message: string;
}

const Error = ({ title = "", message = "" }: ErrorProps) => {

  return (
    <div>
      <Box sx={{ margin: "auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Card className={"card-width"}>
            <CardContent>
              <ErrorMessage errorCode={title} errorMessage={message} />
            </CardContent>
            <CardContent>
              <Box textAlign="center" my={3}>
                <Button
                  href={process.env.REACT_APP_UTILITIES_INFO_URL}
                  variant="contained"
                  color="secondary"
                  size="large"
                >
                  View Account
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </div>
  );
};

export default Error;
