import React from "react";
import { Box, Grid, Card, CardContent, Typography, Link } from "@mui/material";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _colors from "../../../assets/styles/Content/_colors.scss";

const CommunityCollect = () => {

    return (
        <div className="content-wrapper">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={10}
            >
                <Card className={"card-width"}>
                    <CardContent>
                        <Typography color="lighblue" my={3}>
                            <FontAwesomeIcon icon={faCircleInfo} size="5x" color={_colors.lightblue} />
                        </Typography>
                        <Box textAlign="center">
                            <Typography variant="h5">
                                Conservice does not accept payments for this community. Please contact your leasing office about payment options.
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardContent>
                        <Box textAlign="center">
                            <Typography variant="body1" m={4}>
                                If you would like to view your bills&nbsp;
                                <Link
                                    href={process.env.REACT_APP_UTILITIES_INFO_URL}
                                    variant="body1"
                                    color={'secondary'}
                                >
                                    sign in
                                </Link>
                                &nbsp;to your account
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

export default CommunityCollect;
