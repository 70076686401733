import { TenantInformationResponse } from "../../interfaces/tenantQuickPayPaymentInterfaces";


const visaRegex = /^4[0-9]{6,}$/;
const americanExpressRegex = /^3[47][0-9]{5,}$/;
const masterCardRegex =
  /^(?:5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,})$/;
const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{3,}$/;
const dinersClubRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;
const jcbRegex = /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/;

export const GetCreditCardIssuerFee = (
  cardNumber: string | undefined | null,
  values: TenantInformationResponse
): number => {
  if (typeof cardNumber === "undefined" || cardNumber === null) {
    return 3;
  }

  cardNumber = cardNumber.trim();

  if (visaRegex.test(cardNumber)) {
    return values.paymentInfo.visaFee;
  }

  if (americanExpressRegex.test(cardNumber)) {
    return values.paymentInfo.americanExpressFee;
  }

  if (masterCardRegex.test(cardNumber)) {
    return values.paymentInfo.masterCardFee;
  }

  if (discoverRegex.test(cardNumber)) {
    return values.paymentInfo.discoverFee;
  }

  if (dinersClubRegex.test(cardNumber)) {
    return values.paymentInfo.dinersClubFee;
  }

  if (jcbRegex.test(cardNumber)) {
    return values.paymentInfo.jcbFee;
  }

  return values.paymentInfo.defaultCreditCardFee;
};
