import axios from "axios";
import { ZendeskRequest } from "../interfaces/zendeskTicketFeedbackInterfaces";

const url = process.env.REACT_APP_TENANT_QUICKPAY_SERVICE_URL;

const submitZendeskTicket = (zendeskTicket: ZendeskRequest, authToken: string) => {
  const api = axios.create({
    baseURL: url,
    headers: {
      AppName: "QuickPay",
      Authorization: `Bearer ${authToken}`,
    },
  });

  const REQUEST_URL = "Zendesk/CreateZendeskTicket"  
  const result = api.post(REQUEST_URL, zendeskTicket).then((response) => {
    return response.data;
  });

  return result;
};

export const zendeskTicketService = {
  submitZendeskTicket,
};
