import React, { useContext } from "react";
import { DataContext } from "../../context/context";
import CommunityCollect from "./components/CommunityCollect";
import ConserviceCollect from "./components/ConserviceCollect";
import Notification from "../notification/NotificationPage";


const CollectTypePage = () => {
    const { searchData } = useContext(DataContext);

    return (
        <>
            {!searchData.collectType &&
                <Notification
                    title={"INTERNAL_ERROR"}
                    message={"Something went wrong while processing your request, please try again later."} />
            }
            {searchData.collectType === "Community" && <CommunityCollect />}
            {searchData.collectType === "Conservice" && <ConserviceCollect />}
        </>
    );
};

export default CollectTypePage;
