import React, { useContext, useState } from 'react';
import { Typography, TextField, Backdrop, CircularProgress, Box, Card, CardContent, Button, Link, Grid } from "@mui/material";
import { SubmitHandler, useForm } from 'react-hook-form';
import { DataContext } from '../../context/context';
import { VerifyVerificationCodeRequest, VerifyVerificationCodeResponse } from '../../interfaces/accountVerificationInterfaces';
import { tenantQuickPayService } from '../../services/tenantQuickPayService';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import ReactGA from "react-ga4";
import _colors from '../../assets/styles/Content/_colors.scss';
import Swal from 'sweetalert2';

interface VerificationFormInputs {
    code: string;
}

const isLive = process.env.REACT_APP_ENVIRONMENT === "production" ? true : false;

const VerifyAccountPage = () => {
    const { searchData } = useContext(DataContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { contactType } = location.state

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<VerificationFormInputs>({ mode: "all" });

    const onSubmit: SubmitHandler<VerificationFormInputs> = async (data) => {
        setIsLoading(true);
        const verificationCodeRequest: VerifyVerificationCodeRequest = {
            verificationCodeToken: searchData.verificationCodeToken,
            verificationCode: data.code,
        };

        try {
            const verificationCodeResponse: VerifyVerificationCodeResponse = await tenantQuickPayService.verifyVerificationCode(verificationCodeRequest);

            if(!verificationCodeResponse.isSuccessful) {
                reset();
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Unable to Verify",
                    text: "The verification code you entered is incorrect. Please try again.",
                    confirmButtonText: "Close",
                    confirmButtonColor: _colors.primaryblue
                  });
            }

            if (verificationCodeResponse.isSuccessful && verificationCodeResponse.redirectUrl) {
                console.log(window.location.origin);
                // Change the url in test
                if (!isLive) {
                    verificationCodeResponse.redirectUrl = verificationCodeResponse.redirectUrl.replace("https://quickpay.conservice.com", window.location.origin.toString());
                }
                
                window.location.replace(verificationCodeResponse.redirectUrl);
            }
            else if(verificationCodeResponse.isSuccessful && !verificationCodeResponse.redirectUrl) {
                navigate("/account-collect", { replace: true });
            }
            
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="content-wrapper">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={10}
            >
                <Card className={"card-width"}>
                    <FontAwesomeIcon icon={faLock} size="5x" color={_colors.primaryblue} />
                    <CardContent>
                        <Typography className="verification-content" variant="h6" component="h2" mb={2}>
                            A verification code has been sent to the {contactType ?? "contact info"} we have on file. It will expire in 10 minutes.
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Typography className="verification-content" variant="h6" component="h2" mb={2}>
                            Please enter the verification code below:
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                label="Enter Verification Code"
                                className={"textbox-width"}
                                autoComplete="off"
                                sx={{ mt: 2 }}
                                error={!!errors.code}
                                inputProps={{ maxLength: 75 }}
                                helperText={errors.code ? errors.code?.message : ""}
                                {...register("code", {
                                    required: "Verification Code is required",
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "Numeric numbers only",
                                    },
                                    maxLength: {
                                        value: 15,
                                        message: "verification code exceeds maximum length",
                                    }
                                })}
                            />
                            <Box textAlign="center" mt={2} >
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    sx={{ my: 2 }}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                >
                                    Verify
                                </Button>
                            </Box>
                            <Typography className="verification-content" variant="body1" component="h2" m={3}>
                                If you have not recieved a code, try again or&nbsp;
                                <Link
                                    href={process.env.REACT_APP_UTILITIES_INFO_URL}
                                    onClick={() => { ReactGA.event({ category: "Verify Account", action: "Redirect to UI Home Page" }); }}
                                    variant="body1"
                                    color={'secondary'}
                                >
                                    sign in
                                </Link>
                                &nbsp;in to your account
                            </Typography>
                            <Backdrop
                                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </div>

    );
}

export default VerifyAccountPage;