import React, { useEffect } from "react";
import { Box, Grid, Card, CardContent } from "@mui/material";
import CreditCardForm from "./components/CreditCardForm";
import TenantInfo from "./components/TenantInfo";
import ReactGA from "react-ga4";

const PaymentPage = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Payment Page" });
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <Box sx={{ margin: "auto" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Card className={"card-width"}>
              <CardContent>
                <Box textAlign="center">
                  <TenantInfo />
                </Box>
              </CardContent>
              <CardContent>
                <Box textAlign="center">
                  <CreditCardForm />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default PaymentPage;
