import React from "react";
import DynamicNotificationComponent from "../../components/DynamicNotificationComponent/DynamicNotificationComponent";
import { getNotificationType } from "../../utils/helpers/notificationHelper";

interface NotificationInfo {
  title?: string;
  message?: string;
}

const Notification = ({ title = "", message = "" }: NotificationInfo) => {

  const notificationType = getNotificationType(title, message);

  return (
    <div className="content-wrapper">
      <DynamicNotificationComponent componentName={notificationType.componentName} title={notificationType.title} message={notificationType.message} />
    </div>
  );
};

export default Notification;
