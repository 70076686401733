import { OneTimeCCPaymentRequest } from "../../interfaces/tenantQuickPayPaymentInterfaces";

// Other option would be this one: https://gist.github.com/ShirtlessKirk/2134376
const luhnCheck = (creditCardNumber: string) => {
  let nCheck = 0;
  let bEven = false;

  for (var n = creditCardNumber.length - 1; n >= 0; n--) {
    let cDigit = creditCardNumber.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0 ? true : "Invalid Card Number";
};

const validateEftRoutingNumber = (eftRoutingNumber: string) => {
  const numberArray = Array.from(eftRoutingNumber).map(
    (value) => value.charCodeAt(0) - "0".charCodeAt(0)
  );

  let result: boolean;

  if (numberArray.length !== 9) {
    result = false;
  } else if (numberArray[0] === 0) {
    result = true;
  } else {
    const checkSumValue =
      (7 * (numberArray[0] + numberArray[3] + numberArray[6]) +
        3 * (numberArray[1] + numberArray[4] + numberArray[7]) +
        9 * (numberArray[2] + numberArray[5])) %
      10;
    result = checkSumValue === numberArray[8];
  }
  return result ? true : "Invalid Routing Number";
};

const nonAsciiCommasCheck = (input: string) => {
  const hasNonAsciiCharacters = /[^\u0000-\u007f]/;
  const hasCommas = /,(?=[^,]*$)/;

  if (hasNonAsciiCharacters.test(input)) {
    return "Commas and special characters are not allowed";
  }

  if (hasCommas.test(input)) {
    return "Commas and special characters are not allowed";
  }

  return true;
};

const validateMonth = (month: string, values: OneTimeCCPaymentRequest) => {
  const today = new Date();
  const baseMonth = today.getMonth() + 1;
  const baseYear = today.getFullYear();

  let errorMessage = null;
  let monthNumber = parseInt(month);
  if (monthNumber < baseMonth) {
    let yearNumber = parseInt(values.expiryYear);

    if (yearNumber === baseYear) {
      return errorMessage = "Expiration Date is in the past";
    }
  }

  return true;
};

const validateYear = (year: string, values: OneTimeCCPaymentRequest) => {
  const today = new Date();
  const baseMonth = today.getMonth() + 1;
  const baseYear = today.getFullYear();
  
  let errorMessage = null;
  let yearNumber = parseInt(year);

  if (yearNumber === baseYear) {
    let monthNumber = parseInt(values.expiryMonth);

    if (monthNumber < baseMonth) {
      return errorMessage = "Expiration Date is in the past";
    }
  }

  return true
}


export const formValidations = {
  luhnCheck,
  validateEftRoutingNumber,
  nonAsciiCommasCheck,
  validateMonth,
  validateYear,
}