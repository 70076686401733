

export const getNotificationType = (code: string, newMessage: string) => {
    switch (code) {
        case "MAKE_ONE_TIME_PAYMENT_API_FAILED":
            return { componentName: "Error", title:"Payment Failed",  message: newMessage};
        case "CURRENT_BALANCE_IS_ZERO":
            return { componentName: "Information", title:"Zero Balance",  message: newMessage};
        case "LINK_HAS_EXPIRED":
            return { componentName: "Error",  title:"Link Expired",  message: newMessage};
        case "FAILED_TO_PARSE_TOKEN":
            return { componentName: "ContactCustomerService", title:"Invalid Token",  message: newMessage};
        case "ACOUNT_NOT_ALLOWED_TO_MAKE_PAYMENTS":
            return { componentName: "Error",  title:"Payments Not Allowed",  message: newMessage};
        case "404":
            return { componentName: "Error",  title:"Page Not Found",  message: newMessage};
        case "MAINTENANCE_TAKE_DOWN":
            return { componentName: "Maintenance",  title:"Maintenance",  message: newMessage};
         case "INTERNAL_ERROR":
            return { componentName: "Error",  title:"Request Failed",  message: newMessage};
        case "ERROR_MISSING_TOKEN":
            return { componentName: "ContactCustomerService",  title:"",  message: newMessage};
        case "TENANT_DOES_NOT_EXISTS":
            return { componentName: "ContactCustomerService",  title:"",  message: newMessage};
        default:
            return { componentName: "ContactCustomerService", title:"",  message: newMessage};
    }
} 