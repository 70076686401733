import React, { useCallback, useEffect } from "react";
import { Button } from "@mui/material";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface RecaptchaAction {
    onVerifyCaptcha: (token: string) => void;
    text: string;
    action: string;
    isLoading: boolean;
}

const ReCaptchaButton = ({ onVerifyCaptcha, text, action, isLoading }: RecaptchaAction) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha(action);

        // SET THE TOKEN 
        onVerifyCaptcha(token);

    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return <Button
        type="submit"
        disabled={isLoading}
        sx={{ mb: 2 }}
        variant="contained"
        color="secondary"
        size="large" onClick={handleReCaptchaVerify}>{text}</Button>;
};


export default ReCaptchaButton;
