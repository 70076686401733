import React from "react";
import { Button, Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _colors from "../../../assets/styles/Content/_colors.scss";

interface InformationProps {
    title: string;
    message: string;
}

const Information = ({ title = "", message = "" }: InformationProps) => {
    return (
        <div>
            <Box sx={{ margin: "auto" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                >
                    <Card className={"card-width"}>
                        <CardContent>
                            <Typography color="lighblue" my={3}>
                                <FontAwesomeIcon icon={faCircleInfo} size="5x" color={_colors.lightblue} />
                            </Typography>
                            <Box textAlign="center">
                                <Typography variant="h3" my={3}>
                                    {title}
                                </Typography>
                            </Box>
                            <Box textAlign="center" my={3}>
                                <Typography variant="h5">
                                    {message}
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardContent>
                            <Box textAlign="center" my={3}>
                                <Button
                                    href={process.env.REACT_APP_UTILITIES_INFO_URL}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                >
                                    View Account
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Box>
        </div>
    );
};

export default Information;
