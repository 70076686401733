import React, { useEffect } from 'react';
import { Grid, Typography, Box, InputLabel, Link, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./Home.css";
import SearchEmailAddress from "./components/SearchEmailAddress";
import SearchPhoneNumber from './components/SearchPhoneNumber';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactGA from "react-ga4";

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const Home = () => {
  const [selected, setSelected] = React.useState("1");

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home Page" });
  }, []);

  return (
    <>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        mt={8}
        sx={{ paddingBottom: "150px" }}
      >
        <Grid item className="home-card-style" sm={12} lg={3}>
          <Typography variant="h3" my={4}>
            Welcome to <br /> Quick Pay
          </Typography>

          <Typography variant="body1" m={4}>
            You should have a link in your email to make a payment on your account.
          </Typography>

          <Typography variant="body1" m={4}>
            If you can't find your link you can&nbsp;
            <Link
              href={process.env.REACT_APP_UTILITIES_INFO_URL}
              onClick={() => { ReactGA.event({ category: "Home", action: "Redirect to UI Home Page" }); }}
              variant="body1"
              color={'secondary'}
            >
              sign in
            </Link>
            &nbsp;to your account
          </Typography>

        </Grid>
        <Grid item className="home-card-style2" sm={12} lg={3}>

          <Typography variant="body1" mt={4} mb={2} mr={4} ml={4}>Search for a Quick Pay link by using the last four digits of your account number and email address or phone number.</Typography>
          <Box>
            <InputLabel id="select-label">Search By</InputLabel>
            <Select
              labelId="dselect-label"
              defaultValue={"1"}
              size="small"
              sx={{ mt: 1 }}
              onChange={handleChange}
            >
              <MenuItem value={"1"}>Email Address</MenuItem>
              <MenuItem value={"2"}>Phone Number</MenuItem>
            </Select>
          </Box>
          <GoogleReCaptchaProvider reCaptchaKey={`${siteKey}`}>
            {selected === "1" && <SearchEmailAddress />}
            {selected === "2" && <SearchPhoneNumber />}
          </GoogleReCaptchaProvider>

        </Grid>
      </Grid>
    </>
  );
}

export default Home;