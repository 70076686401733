import React from "react";
import ReportIcon from "@mui/icons-material/Report";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Button, Box, Grid, Card, CardContent, Typography, } from "@mui/material";

interface ContactCustomerServiceProps {
  title: string;
  message: string;
}

const ContactCustomerService = ({ title = "", message = "" }: ContactCustomerServiceProps) => {
  return (
    <div>
      <Box sx={{ margin: "auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Card className={"card-width"}>
            <CardContent>
              <Box textAlign="center">
                <Typography color="error" my={3}>
                  <ReportIcon sx={{ fontSize: 100 }} />
                </Typography>
                <Typography variant="h3" my={3}>
                  Contact Customer Service
                </Typography>
                <Typography mt={2}>
                  Please contact customer service at
                </Typography>
                <Button
                  size="large"
                  color="primary"
                  startIcon={<ContactPhoneIcon color="primary" />}
                  href={"tel: 1-866-947-7379"}
                >
                  1-866-947-7379
                </Button>
              </Box>
            </CardContent>
            <CardContent>
              <Box textAlign="center" my={3}>
                <Button
                  href={process.env.REACT_APP_UTILITIES_INFO_URL}
                  variant="contained"
                  color="secondary"
                  size="large"
                >
                  View Account
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </div>
  );
};

export default ContactCustomerService;
