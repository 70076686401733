import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function ReviewTermsModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Box mt={2}>
        <Button variant="text" color="secondary" onClick={handleOpen}>
          Read Terms
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content-wrapper">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Credit Card Terms
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            I hereby authorize Conservice to initiate a charge to my credit card
            for the total amount charged for my Conservice account. A service
            fee will be charged for this one-time online payment. Please note
            that this is an optional payment method provided for your
            convenience only. To avoid this fee you may mail a payment, enroll
            in one of our free recurring monthly payment options. I acknowledge
            that a fee may be charged by Conservice in the event that Conservice
            is unable to secure funds from my credit card for any reason;
            including, but not limited to, insufficient funds or inaccurate
            information provided at the time the payment is submitted. The
            amount of the fee is administered in accordance with applicable
            state and federal law.
          </Typography>
          <Button color="secondary" onClick={handleClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default ReviewTermsModal;
