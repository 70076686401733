import React, { useContext } from "react";
import { Typography, Chip, Divider, Link } from "@mui/material";
import { DataContext } from "../../../context/context";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TenantInfo = () => {
  const { tenantData } = useContext(DataContext);

  return (
    <>
      <Typography variant="h3" my={2}>
        Quick Pay
      </Typography>
      <Typography variant="subtitle1" mt={1} mb={2}>
        A simple way to make a one-time payment for your account. Or <Link href={process.env.REACT_APP_UTILITIES_INFO_URL} color="secondary">log in</Link> to pay.
      </Typography>
      <Divider>
        <Chip label="Account Info" variant="outlined" />
      </Divider>
      <Typography gutterBottom variant="h6" mt={2}>
        Last Name: {tenantData.paymentInfo.lastName}
      </Typography>
      <Typography gutterBottom variant="h6" mb={2}>
        Account #: xxxx{tenantData.paymentInfo.tenantCode.slice(-4)}
      </Typography>
      <Divider>
        <Chip label="Payment Info" variant="outlined" />
      </Divider>
      {tenantData.paymentInfo.recentPaymentAmount !== null && (
        <Typography className="information-notification-bubble" variant="body2" mt={2}>
          <FontAwesomeIcon icon={faCircleInfo} /> A payment was made in the last 24 hours for ${tenantData.paymentInfo.recentPaymentAmount.toFixed(2)}.
        </Typography>
      )}

      <Typography gutterBottom variant="h6" mt={2}>
        Current Balance: ${tenantData.paymentInfo.currentBalance.toFixed(2)}
      </Typography>
    </>
  );
};

export default TenantInfo;
