import React, { useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { SearchByPhoneNumberRequest, SearchResponse } from '../../../interfaces/accountVerificationInterfaces';
import { tenantQuickPayService } from "../../../services/tenantQuickPayService";
import { Stack, CircularProgress, Backdrop, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/context";
import Swal from "sweetalert2";
import _colors from "../../../assets/styles/Content/_colors.scss";
import ReCaptchaButton from "../../../components/ReCaptcha/ReCaptchaButton";
import BillInfoModal from "../../../components/BillInfoModal/BillInfoModal";
import ReactGA from "react-ga4";

const SearchPhoneNumber = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { setSearchData } = useContext(DataContext);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<SearchByPhoneNumberRequest>({ mode: "all" });

    const onSubmit: SubmitHandler<SearchByPhoneNumberRequest> = async (data) => {

        setIsLoading(true);

        try {

            const response: SearchResponse = await tenantQuickPayService.SearchPhoneNumber(data);

            if (response.isSuccessful) {

                ReactGA.event({ category: "Search", action: `Search Phone ${response.collectType}` });

                setSearchData(response);

                if (response.collectType === "Conservice") {
                    navigate("/verify-account", { replace: true, state: { contactType: "phone number" } });
                }
                else {
                    navigate("/account-collect", { replace: true });
                }
            }
            else {

                ReactGA.event({ category: "Search", action: "Search Phone Failed" });

                Swal.fire({
                    text: response.errorMessage,
                    icon: 'error',
                    confirmButtonText: 'Okay',
                    confirmButtonColor: _colors.primaryblue,
                })

            }

        } catch (e) {
            console.log(e);

            Swal.fire({
                text: 'Something went wrong while processing your request, please try again later.',
                icon: 'error',
                confirmButtonText: 'Okay',
                confirmButtonColor: _colors.primaryblue,
            })
        }

        setIsLoading(false);
    };

    const onVerifyCaptcha = (token: string) => {
        setValue("recaptchaToken", token);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2} mt={3} mb={2} mr={4} ml={4}>
                    <TextField
                        label="Last four Account Number"
                        InputProps={{ endAdornment: <BillInfoModal /> }}
                        autoComplete="off"
                        size="small"
                        sx={{ mt: 1 }}
                        error={!!errors.lastFourAccountNumber}
                        helperText={errors.lastFourAccountNumber ? errors.lastFourAccountNumber?.message : ""}
                        {...register("lastFourAccountNumber", {
                            required: "Last four account number is required",
                            pattern: {
                                value: /^\d+$/,
                                message: "Numeric numbers only",
                            },
                            maxLength: {
                                value: 4,
                                message: "Last four account number exceeds maximum length",
                            },
                        })}
                    />
                    <TextField
                        label="Phone Number"
                        inputProps={{ maxLength: 10 }}
                        autoComplete="off"
                        size="small"
                        sx={{ mt: 1 }}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber ? errors.phoneNumber?.message : ""}
                        {...register("phoneNumber", {
                            required: "Phone Number is required",
                            pattern: {
                                value: /^\d+$/,
                                message: "Numeric numbers only",
                            },
                            maxLength: {
                                value: 10,
                                message: "Phone number exceeds maximum length",
                            },
                        })}
                    />
                </Stack>
                <ReCaptchaButton onVerifyCaptcha={onVerifyCaptcha} text="Search" action="SearchPhoneNumber" isLoading={isLoading} />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            </form>
        </div>
    )
}

export default SearchPhoneNumber;
